import styled from 'styled-components';

export const PageContainer = styled.div`
  font-family: BMWGroupCondensed-Regular;
`;

export const SummaryContainer = styled.div`
  break-before: page;
`;

export const HeaderContainer = styled.div`
  padding: 15px 0px 20px 10px;
  position: relative;
`;

export const FooterContainer = styled.div`
  margin: 20px 0px 20px 0px;
  padding-left: 5px;
`;

export const SectionHeader = styled.div`
  width: 100%;
  background-color: #6e2262;
  padding: 0px 10px;
  color: #ffffff;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

export const VehiclePropertiesColumnContainer = styled.div`
  width: 100%;
  padding: 12px 8px;
  display: flex;
  flex-direction: column;
`;

export const VehiclePropertiesRowContainer = styled.div`
  width: 100%;
  padding: 12px 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PropertiesListItem = styled.div``;

export const TitleText = styled.div`
  color: #6a6a6a;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-transform: uppercase;
`;

export const ValueText = styled.div`
  color: #333333;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

export const ListContainer = styled.div`
  width: 100%;
  padding: 12px 8px;
`;

export const ListItem = styled.div`
  padding: 10px 0px 20px 0px;
  width: 100%;

  @media print, screen and (min-width: 50px) {
    width: 100%;
    padding: 10px 0px 10px 0px;
  }
`;

export const ReviewContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 15px;
`;

export const Review = styled.div`
  color: #6e2262;
  font-family: Roboto;
  font-size: 16px;
  padding-left: 5px;
`;

export const pdfTitle = styled.div`
  color: #6e2262;
  font-family: Roboto;
  font-size: 16px;
  padding-left: 5px;
`;

export const InfoContainer = styled.div``;

export const InfoContent = styled.span`
  margin-left: 8px;
  color: #333333;
  font-family: Roboto;
  font-size: 16px;
  line-height: 20px;
`;

export const DamageType = styled.div`
  color: black;
  font-family: Roboto;
  font-size: 16px;
  line-height: 20px;
`;
