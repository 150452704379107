import React from 'react';
import styled from 'styled-components';

import { VehiclePropertiesListContainer, getListElements } from './vehiclePropertiesListStyles';
import { formatCurrency } from 'common/helpers/formatCurrency';
import { ClientApp } from 'common/model/ClientApp';
import { TopLine, BMWHeader, PoppinsHeader, BMWPdfHeader } from '../helpers/globalStyles';

interface Props {
  totalMileageCharge: number;
  leaseProtectionBenefitAmount: number;
  dispositionFeeAmount: number | undefined;
  bodyAssessmentTotal: number;
  partsChargesTotal: number;
  optionChargesTotal: number;
  clientApp: ClientApp;
  isConsumerInspection?: boolean;
  isPdfRendering?: boolean;
}

export const Summary: React.FC<Props> = ({
  totalMileageCharge,
  leaseProtectionBenefitAmount,
  dispositionFeeAmount = 0,
  bodyAssessmentTotal,
  partsChargesTotal,
  optionChargesTotal,
  clientApp,
  isConsumerInspection,
  isPdfRendering
}) => {
  const grandTotal =
    bodyAssessmentTotal +
    partsChargesTotal +
    optionChargesTotal +
    totalMileageCharge +
    dispositionFeeAmount -
    leaseProtectionBenefitAmount;
  const totalTitle = clientApp === ClientApp.Dealer ? 'GRAND TOTAL' : 'Pre-Term Inspection Grand Total';

  const { Title, Value } = getListElements({ isConsumerInspection, isPdfRendering });
  const { SectionHeader, Item } = isConsumerInspection
    ? { SectionHeader: isPdfRendering ? ConsumerStylePDFHeader : ConsumerStyleHeader, Item: ListItem }
    : { SectionHeader: Header, Item: ItemContainer };

  return (
    <SummaryContainer isConsumerInspection={isConsumerInspection}>
      <SectionHeader>Summary of Estimated Excess Wear-and-Use and Mileage Charges*</SectionHeader>
      {!isConsumerInspection && (
        <SectionDescription>
          * Other fees may apply (e.g., property tax, unpaid tickets, etc.) Per your contract, end of term charges may
          be subject to local and state taxes.
        </SectionDescription>
      )}
      <VehiclePropertiesListContainer isConsumerInspection={isConsumerInspection}>
        <Item>
          <Title>Body Assessment Total</Title>
          <Value>{formatCurrency(bodyAssessmentTotal)}</Value>
        </Item>
        <Item>
          <Title>Parts Total</Title>
          <Value>{formatCurrency(partsChargesTotal)}</Value>
        </Item>
        <Item>
          <Title>Options Total</Title>
          <Value>{formatCurrency(optionChargesTotal)}</Value>
        </Item>
        <Item>
          <Title>Total Excess Mileage Charge</Title>
          <Value>{formatCurrency(totalMileageCharge)}</Value>
        </Item>
        <Item>
          <Title>Disposition Fee**</Title>
          <Value>{formatCurrency(dispositionFeeAmount)}</Value>
        </Item>
        <Item>
          {isConsumerInspection ? (
            <Title>Potential BMW Lease Protection Benefit (dependent on claim approval)</Title>
          ) : (
            <Title>
              POTENTIAL BMW LEASE PROTECTION BENEFIT
              <br />
              (DEPENDENT ON CLAIM APPROVAL)
            </Title>
          )}
          <Value>
            {leaseProtectionBenefitAmount === 0
              ? formatCurrency(0)
              : `(${formatCurrency(leaseProtectionBenefitAmount)})`}
          </Value>
        </Item>
        {!isConsumerInspection && (
          <Item>
            <Title>{totalTitle}</Title>
            <GrandTotalValue>{formatCurrency(grandTotal)}</GrandTotalValue>
          </Item>
        )}
      </VehiclePropertiesListContainer>
    </SummaryContainer>
  );
};

const SummaryContainer = styled.div<{
  isConsumerInspection?: boolean;
}>`
  break-inside: avoid-page;
  ${props => (props.isConsumerInspection ? TopLine : '')}
`;

const Header = styled.div`
  width: 100%;
  background-color: #eeefea;
  display: flex;
  justify-content: space-between;
  padding: 12px 10px;
  ${BMWHeader}
  text-transform: uppercase;
`;

const ConsumerStyleHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0 0;
  ${PoppinsHeader}
`;

const ConsumerStylePDFHeader = styled(ConsumerStyleHeader)`
  ${BMWPdfHeader}
`;

const SectionDescription = styled.div`
  color: #666666;
  font-size: 19px;
  font-family: BMWGroupCondensed-Regular;
  padding-top: 15px;
  padding-left: 8px;
  padding-right: 8px;
`;

export const GrandTotalValue = styled.div`
  color: black;
  font-size: 19px;
  font-family: BMWGroupCondensed-Bold;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px 20px 0px;
  width: 100%;
  @media print, screen and (min-width: 680px) {
    width: 55%;
    padding: 10px 0px 10px 0px;
  }
`;

export const ListItem = styled(ItemContainer as any)`
  padding: 0 0 8px;
  @media print, screen and (min-width: 680px) {
    width: 100%;
    padding: 0 0 8px;
  }
`;
